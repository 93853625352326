<template>
  <div class="particles">
    <div id="particles-js"></div>
  </div>
</template>

<script>
const blobs = [
  "ab1nzyblob2.gif",
  "ablobangel.gif",
  "ablobattention.gif",
  "ablobattentionreverse.gif",
  "ablobaww.gif",
  "ablobbanhammer.gif",
  "ablobbass.gif",
  "ablobblastoff.gif",
  "ablobblewobble.gif",
  "ablobbounce.gif",
  "ablobcaramelldansen.gif",
  "ablobcheer.gif",
  "ablobcolorshift.gif",
  "ablobcongarollbounce.gif",
  "ablobcouncil.gif",
  "ablobcouple.gif",
  "ablobcry.gif",
  "ablobdancer.gif",
  "ablobderpy.gif",
  "ablobderpyhappy.gif",
  "ablobdevil.gif",
  "ablobdizzy.gif",
  "ablobdrool.gif",
  "ablobdrum.gif",
  "ablobdundundun.gif",
  "ablobenjoyrain.gif",
  "ablobeyes.gif",
  "ablobflushed.gif",
  "ablobgift.gif",
  "ablobglarezoombutfast.gif",
  "ablobglarezoom.gif",
  "ablobgoodnight.gif",
  "ablobgoodnightreverse.gif",
  "ablobgrimace.gif",
  "ablobgrin.gif",
  "ablobguitar.gif",
  "ablobheadshake.gif",
  "ablobhearteyes.gif",
  "ablobhologram.gif",
  "ablobhop.gif",
  "ablobhungry.gif",
  "ablobhydraulicpress.gif",
  "ablobhype.gif",
  "ablobjoy.gif",
  "ablobkeyboard.gif",
  "ablobkiss.gif",
  "abloblamp.gif",
  "abloblurk.gif",
  "ablobmaracas.gif",
  "ablobmelt.gif",
  "ablobmelting.gif",
  "ablobmeltsoblove.gif",
  "ablobmicrophone.gif",
  "ablobnervous.gif",
  "ablobnod.gif",
  "ablobnogood.gif",
  "ablobnom.gif",
  "ablobnwn.gif",
  "ablobonfire.gif",
  "ablobowo.gif",
  "ablobpainpats.gif",
  "ablobpanic.gif",
  "ablobparty.gif",
  "ablobpats.gif",
  "ablobpeek.gif",
  "ablobpopcorn.gif",
  "ablobpumpkin.gif",
  "ablobrage.gif",
  "ablobraisehand.gif",
  "ablobreach.gif",
  "ablobreachreverse.gif",
  "ablobrollbounce.gif",
  "ablobrollingeyes.gif",
  "ablobsad.gif",
  "ablobsadpats.gif",
  "ablobsadrain.gif",
  "ablobsalute.gif",
  "ablobscream.gif",
  "ablobsigh.gif",
  "ablobsleep.gif",
  "ablobsmile.gif",
  "ablobsmilehappy.gif",
  "ablobsplosion.gif",
  "ablobsquish.gif",
  "ablobsunglasses.gif",
  "ablobsurpriseblush.gif",
  "ablobsweating.gif",
  "ablobsweats.gif",
  "ablobthinkingeyes.gif",
  "ablobthinkingfast.gif",
  "ablobthinking.gif",
  "ablobthinkzerogravity.gif",
  "ablobtipping.gif",
  "ablobtoiletflush.gif",
  "ablobtonguewink.gif",
  "ablobunamused.gif",
  "ablobuwu.gif",
  "ablobwave.gif",
  "ablobwavereverse.gif",
  "ablobweary.gif",
  "ablobwhee.gif",
  "ablobwink.gif",
  "ablobwoah.gif",
  "ablobwoahsnow.gif",
  "ablobwob.gif",
  "ablobzerogravity.gif",
  "abongoblob.gif",
  "acongablob.gif",
  "adontfeelsoblob.gif",
  "ajakeblob.gif",
  "amegablobsweats.gif",
  "apartyblob.gif",
  "aphotoblob.gif",
  "arainblob.gif",
  "athinkingwithblobs.gif",
  "awolfiriblob.gif",
  "b1nzyblob2.png",
  "b1nzyblob.png",
  "b4nzyblob.png",
  "blob0w0.png",
  "blob3c.png",
  "blobamused.png",
  "blobangel.png",
  "blobangery.png",
  "blobangry.png",
  "blobartist.png",
  "blobaviator.png",
  "blobawkward.png",
  "blobaww.png",
  "blobbandage.png",
  "blobbanhammer.png",
  "blobblush.png",
  "blobboost.png",
  "blobbored.png",
  "blobbot.png",
  "blobbowing.png",
  "blobbroken.png",
  "blobbws.png",
  "blobcamera.png",
  "blobcat.png",
  "blobcheeky.png",
  "blobcheerful2.png",
  "blobcheerful.png",
  "blobcheer.png",
  "blobcmereyou.png",
  "blobconfounded.png",
  "blobconfused.png",
  "blobcool.png",
  "blobcornhat.png",
  "blobcouncil.png",
  "blobcouple.png",
  "blobcowboy.png",
  "blobcry.png",
  "blobdancer.png",
  "blobdead.png",
  "blobderpyhappy.png",
  "blobderpy.png",
  "blobdetective.png",
  "blobdevil.png",
  "blobdisapproval.png",
  "blobdizzy.png",
  "blobdoctor.png",
  "blobdoubtful.png",
  "blobdoubt.png",
  "blobdrool.png",
  "blobeagle.png",
  "blobembarrassed2.png",
  "blobembarrassed.png",
  "blobenjoy.png",
  "blobexpressionless.png",
  "blobeyesdown.png",
  "blobeyesup.png",
  "blobfacepalm.png",
  "blobfearful.png",
  "blobfingerguns.png",
  "blobfingerscrossed.png",
  "blobfistbumpL.png",
  "blobfistbumpR.png",
  "blobflushed.png",
  "blobfreezing.png",
  "blobfrog.png",
  "blobfrowningbig.png",
  "blobfrowning.png",
  "blobfrown.png",
  "blobgamer.png",
  "blobgift.png",
  "blobgiggle.png",
  "blobglarenervous.png",
  "blobglare.png",
  "blobglassesdown.png",
  "blobgoat.png",
  "blobgoodmorning.png",
  "blobgoodmorningreverse.png",
  "blobgoodnight.png",
  "blobgoodnightreverse.png",
  "blobgo.png",
  "blobgrimace.png",
  "blobgrin.png",
  "blobheadphones.png",
  "blobhearteyes.png",
  "blobheart.png",
  "blobhero.png",
  "blobhighfive.png",
  "blobhug2.png",
  "blobhug.png",
  "blobhuh.png",
  "blobhushed.png",
  "blobhyperthinkfast.png",
  "blobhyperthink.png",
  "blobhypesquad.png",
  "blobidea.png",
  "blobimfine.png",
  "blobinlove.png",
  "blobjoy.png",
  "blobkissblush.png",
  "blobkissheart.png",
  "blobkiss.png",
  "bloblamp.png",
  "bloblul.png",
  "blobmail.png",
  "blobmaracas.png",
  "blobmeltblush.png",
  "blobmelt.png",
  "blobmeltsoblove.png",
  "blobmeltsob.png",
  "blobmindblown.png",
  "blobmlem.png",
  "blobmorning.png",
  "blobmoustache.png",
  "blobnauseated.png",
  "blobnerd.png",
  "blobnervous.png",
  "blobneutral.png",
  "blobnight.png",
  "blobninja.png",
  "blobnitro.png",
  "blobnogood.png",
  "blobnomchristmas.png",
  "blobnomcookie.png",
  "blobnomglobal1.png",
  "blobnomglobal2.png",
  "blobnomglobal3.png",
  "blobnomouth.png",
  "blobnom.png",
  "blobno.png",
  "blobnostar2.png",
  "blobnostar.png",
  "blobnwn.png",
  "blobokhand.png",
  "blobok.png",
  "blobonfire.png",
  "bloboohcry.png",
  "blobooh.png",
  "blobopenmouth.png",
  "bloboro.png",
  "bloboutage.png",
  "blobovercuteness.png",
  "bloboverheated.png",
  "blobowo2.png",
  "blobowoevil.png",
  "blobowo.png",
  "blobpainpats.png",
  "blobpain.png",
  "blobpanic.png",
  "blobpan.png",
  "blobpartlysunny.png",
  "blobparty.png",
  "blobpatrol.png",
  "blobpats.png",
  "blobpeek.png",
  "blobpensive.png",
  "blobpin.png",
  "blobpirate.png",
  "blobpleading.png",
  "blob.png",
  "blobpoliceangery.png",
  "blobpolice.png",
  "blobpoll.png",
  "blobpopcorn.png",
  "blobpopsicle.png",
  "blobpout2.png",
  "blobpout.png",
  "blobpray.png",
  "blobpumpkin.png",
  "blobrageangry.png",
  "blobrage.png",
  "blobreachdrool.png",
  "blobreach.png",
  "blobreachreverse.png",
  "blobreachsob.png",
  "blobrofl.png",
  "blobrollingeyes.png",
  "blobross.png",
  "blobsadpats.png",
  "blobsad.png",
  "blobsadrain.png",
  "blobsalute.png",
  "blobsanta.png",
  "blobscarf.png",
  "blobscream.png",
  "blobsecret.png",
  "blobshh.png",
  "blobshrug.png",
  "blobsick.png",
  "blobsip.png",
  "blobsleeping.png",
  "blobsleepless.png",
  "blobsmilehappyeyes.png",
  "blobsmilehappy.png",
  "blobsmile.png",
  "blobsmilesweat2.png",
  "blobsmilesweat.png",
  "blobsmiley.png",
  "blobsmirk.png",
  "blobsneezing.png",
  "blobsnuggle.png",
  "blobsobglasses.png",
  "blobsob.png",
  "blobspam.png",
  "blobsplosion.png",
  "blobspy.png",
  "blobstare.png",
  "blobstarstruck.png",
  "blobstop.png",
  "blobstudent.png",
  "blobsunglasses.png",
  "blobsurprised.png",
  "blobsweats.png",
  "blobteefs.png",
  "blobthanks.png",
  "blobthief.png",
  "blobthinkingcool.png",
  "blobthinkingdown.png",
  "blobthinkingeyes.png",
  "blobthinkingfast.png",
  "blobthinkingglare.png",
  "blobthinking.png",
  "blobthinkingsmirk.png",
  "blobthinksmart.png",
  "blobthis.png",
  "blobthonkang.png",
  "blobthumbsdown.png",
  "blobthumbsup.png",
  "blobthump.png",
  "blobtilt.png",
  "blobtired.png",
  "blobtongue.png",
  "blobtonguewink.png",
  "blobtorch.png",
  "blobtriumph2.png",
  "blobtriumph.png",
  "blobugh.png",
  "blobunamused.png",
  "blobunsure.png",
  "blobupset.png",
  "blobupsidedown.png",
  "blobuwu.png",
  "blobvomiting.png",
  "blobwaitwhat.png",
  "blobwave.png",
  "blobwavereverse.png",
  "blobweary.png",
  "blobwhistle.png",
  "blobwink.png",
  "blobwitch.png",
  "blobwizard.png",
  "blobwoah.png",
  "blobwob.png",
  "blobwooloo.png",
  "blobwoozy.png",
  "blobworker.png",
  "blobworried.png",
  "blobxd.png",
  "blobyawn.png",
  "blobyes.png",
  "blobyikes.png",
  "blobyum.png",
  "blobzippermouth.png",
  "bolb.png",
  "doggoblob.png",
  "feelsblobman.png",
  "ferretblob.png",
  "gentleblob.png",
  "jakeblob.png",
  "kirbyblob.png",
  "nellyblob.png",
  "nikoblob.png",
  "notlikeblob.png",
  "pandablob.png",
  "photoblob.png",
  "pikablob.png",
  "pusheenblob.png",
  "rainblob.png",
  "reindeerblob.png",
  "rickblob.png",
  "thinkingwithblobs.png",
  "wolfiriblob.png",
  "wumpusblob.png"
];
const blob = blobs[Math.floor(Math.random() * blobs.length)];
import "particles.js";
export default {
  name: "particles",
  mounted() {
    this.initParticles();
  },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 70,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "image",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 6,
            },
            image: {
              src: `/blobs/${blob}`,
              width: 125,
              height: 125,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 16,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "bubble",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 0.414159378273686,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
};
</script>

<style scoped>
#particles-js {
  width: 100%;
  height: 100%;
  position: fixed;
}
.body-particles{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>